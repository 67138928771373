<script>
  import calcArr from "../calcData";
  import Calc from "./calc.svelte";
</script>

<style type="text/postcss">main{margin-top:1.5rem;margin-bottom:1.5rem;padding-left:.5rem;padding-right:.5rem;height:100%;font-family:Open Sans}@media (min-width:768px){main{margin-top:5rem;margin-bottom:5rem;padding-left:4rem;padding-right:4rem}}
/*# sourceMappingURL=src/components/main.svelte.map */</style>

<main>
  {#each calcArr as calcSettings}
    <Calc {calcSettings} />
  {/each}
</main>
