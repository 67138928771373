<script>
  import { fly } from "svelte/transition";
  import { generateUniqueID } from "../utils";

  import Input from "./input.svelte";
  import Button from "./button.svelte";
  import { isNumeric } from "../utils";

  export let calcSettings = {
    id: "",
    inputs: [],
    calcHandler: function(a, b) {}
  };

  let inputValues = {};
  let inputErrors = false;
  let result = null;

  function handleChange() {
    if (!isNumeric(this.value)) return;
    if (inputErrors) return (inputErrors = false);

    inputValues[this.id] = this.value;
  }

  function handleSubmit() {
    // check if inputValues is complete
    if (
      !Object.keys(inputValues).length ||
      Object.keys(inputValues).length !== calcSettings.inputs.length
    )
      return (inputErrors = true);

    result = calcSettings.calcHandler(inputValues);
  }
</script>

<style type="text/postcss">section{margin-left:auto;margin-right:auto;margin-top:2rem;padding:1rem;max-width:64rem;background-color:#edf2f7;border-radius:.25rem;box-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);line-height:1.25}section:first-of-type{margin-top:0}h2{font-size:1.5rem;font-weight:600;color:#4a5568}p.example{font-size:.875rem;margin-top:.25rem;margin-bottom:.25rem;color:#38b2ac}.form{margin-top:.5rem;margin-bottom:2rem;display:flex;flex-direction:column}p.error{font-weight:600;color:#f56565}.result{padding-top:.75rem;color:#2d3748;border-top-width:2px;border-color:#cbd5e0}.result em{font-style:normal;font-size:1.25rem;color:#7f9cf5;font-weight:600}@media (min-width:768px){section{margin-top:4rem;padding:1.5rem 3rem}p.example{font-size:1rem}.form{margin-top:3rem;flex-direction:row;align-items:flex-end}}
/*# sourceMappingURL=src/components/calc.svelte.map */</style>

<section>
  <h2>{calcSettings.title}</h2>
  <p class="example">
    <i>Example: {calcSettings.example}</i>
  </p>
  <form class="form" on:submit|preventDefault={handleSubmit}>
    {#each calcSettings.inputs as { label, placeholder }}
      <Input
        id={calcSettings.id}
        {label}
        {placeholder}
        changeHandler={handleChange} />
    {/each}
    <Button />
  </form>
  {#if inputErrors}
    <p class="error" in:fly={{ y: 20, duration: 250 }}>
      Please correct your value(s) entered
    </p>
  {/if}
  {#if result}
    <div class="result" in:fly={{ y: 50, duration: 500 }}>
      <p>
        Result:
        <em>
          {#if calcSettings.percentResult}{result}%{:else}{result}{/if}
        </em>
      </p>
    </div>
  {/if}
</section>
