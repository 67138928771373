<script>
  export let label = "input";
  export let id = "";
  export let placeholder = "";
  export let changeHandler = () => {};

</script>

<style type="text/postcss">div{margin-top:1rem;display:flex;flex-direction:column}label{margin-bottom:.5rem;color:#718096}input{padding:.75rem;width:100%;color:#fff;background-color:#4a5568;border:2px solid #4a5568;outline:0;border-radius:.25rem}input:focus{border-color:#a3bffa}@media (min-width:768px){div{margin-right:5rem}}
/*# sourceMappingURL=src/components/input.svelte.map */</style>

<div>
  <label for={label}>{label}:</label>
  <input
    id={label}
    type="number"
    {placeholder}
    required
    on:change={changeHandler} />
</div>
