<style type="text/postcss">footer{margin-top:auto;padding:2rem;display:flex;flex-direction:column;align-items:center;background-color:#1a202c;font-family:Lato;color:#fff}p{font-size:1.25rem;color:#a0aec0}a{margin-top:.75rem;display:block;border-bottom-width:1px;border-color:#fff}
/*# sourceMappingURL=src/components/footer.svelte.map */</style>

<footer>
  <p>Percentage Calculator</p>
  <a
    href="https://github.com/chrismwilliams"
    target="_blank"
    rel="nofollow noopener noreferrer">
    &copy; Chris Williams
  </a>
</footer>
